module.exports = {
  // App Info
  appstore_link: "#", // Enter App Store URL.
  playstore_link: "#", // Enter Google Play Store URL.
  google_analytics_ID: "UA-47311644-5", // Enter Google Analytics ID or ""
  presskit_download_link: "#", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "video", // "screenshot" or "video"
  app_url: "https://coronavitals.com", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "Corona Vitals",
  app_price: "Free",
  app_description:
    "Monitor the virus spread, while keeping track of your own health",
  app_keywords: ["covid-19", "Coronavirus", "virus", "symptom tracker"],

  // Personal Info
  your_name: "Robert Edwards",
  your_link: "#",
  your_city: "Orange County",
  email_address: "info@coronavitals.com",
  linkedin_username: null,
  facebook_username: null,
  instagram_username: "",
  twitter_username: "",
  github_username: "",
  youtube_username: null,

  // Features List
  features: [
    {
      title: "Track the virus",
      description: "Descriptions for tracking the virus",
      fontawesome_icon_name: "magic",
    },
    {
      title: "See State And County Information",
      description: "Description for state and county data",
      fontawesome_icon_name: "mobile",
    },
    {
      title: "View Resources",
      description: "Latest news on the pandemic",
      fontawesome_icon_name: "play-circle",
    },
    {
      title: "Track Symptoms",
      description: "Track symptoms by entering how you feel whenever possible",
      fontawesome_icon_name: "sync",
    },
    {
      title: "View State Maps",
      description:
        "View the outbreak in the US by state, on easy to read state maps.",
      fontawesome_icon_name: "adjust",
    },
  ],
  header_background: "rgba(255, 255, 255, 0.1)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "coral", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "#ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#1d63ea",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666",
}
