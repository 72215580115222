/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import GlobalStyle from "./style.js"
import Header from "./header"
import Footer from "../components/footer"
import configs from "../../site-config"
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      headerIcon: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <>
      <GlobalStyle />
      <Header
        appName={configs.app_name}
        icon={data.headerIcon}
        downloadLink={configs.presskit_download_link}
      />
      {children}
      <Footer configs={configs} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
